/* COLOR VARIABLES */

$main-color: #EBEBEB;
$main-color50: rgba(#EBEBEB, 0.5);
$main-color-dark: #a5a1a1;
$black: #171719;
$grey: #696969;
$lightgrey: #F4F4F4;
$light: #E0E0E0;
$white: #ececec;
$blue: #4ca7af;
$dark: #111;

/*ALERTS */

$success-color: #3c763d;
$success-back: #dff0d8;
$success-border: #d6e9c6;
$warning-color: #8a6d3b;
$warning-back: #fcf8e3;
$warning-border: #faebcc;
$error-color: #a94442;
$error-back: #f2dede;
$error-border: #ebccd1;

/**/

$nav-size: 50px;
$footer-size: 60px;
$form-width: 350px;
//SPACING//
$spacing--x-small: 0.3125rem; //5px;
$spacing--small: 0.6250rem; //10px
$spacing--medium: 0.9375rem; //15px
$spacing--large: 1.250rem; //20px
$spacing--x-large: 1.5625rem; //25px
$spacing--xx-large: 1.875rem; //30px

/*TEXT SIZE */

$big-text-size:18px;
$medium-text-size:13px;
$small-text-size:11px;
/// Map deep get
/// @author Hugo Giraudel
/// @access public
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Key chain
/// @return {*} - Desired value
@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

$typescale:(
    heading1: (xxs:1.3rem, xs: 1.4rem, sm: 1.5rem, md: 1.6rem, lg: 1.7rem, xl: 1.8rem),
    sub1: (xxs:0.85rem, xs: 0.8rem, sm: 0.9rem, md: 1rem, lg: 1.1rem, xl: 1.2rem),
    text1: (xxs:0.84rem, xs: 0.86rem, sm: 0.87rem, md: 0.86rem, lg: 0.96rem, xl: 0.99rem),
    textbold1: (xxs:0.84rem, xs: 0.86rem, sm: 0.87rem, md: 0.9rem, lg: 0.9rem, xl: 0.9rem),
    smalltext1: (xxs:0.71rem, xs: 0.72rem, sm: 0.73rem, md: 0.76rem, lg: 0.8rem, xl: 0.8rem),
    smallmedium1: (xxs:0.71rem, xs: 0.72rem, sm: 0.73rem, md: 0.76rem, lg: 0.8rem, xl: 0.8rem),
    xsmalltext1: (xxs:0.64rem, xs: 0.65rem, sm: 0.66rem, md: 0.67rem, lg: 0.68rem, xl: 0.69rem),
    xsmallmedium1: (xxs:0.64rem, xs: 0.65rem, sm: 0.66rem, md: 0.67rem, lg: 0.68rem, xl: 0.69rem)
);
