
/*------------------------------------*\
 #BUTTONS
 \*------------------------------------*/

.btn-modal {
     @extend .btn-default;
     max-width: 200px;
     width: 100%;
 }

 .btn-default{
  border: none;
  padding: $spacing--medium;
  background: black;
  color: white;
  width: 100%;
  //font-size: 0.9375rem;
  text-align: center;
  cursor: pointer;
  &:hover{
    opacity: 0.9;
 }
}

.btn-blue{
  @extend .btn-default;
  background: $blue;
} 


.btn-dark{
	@extend .btn-default;
	background: none;
  &:hover{
   background: none;
 }

  }

.btn-danger{
	@extend .btn-default;
	background: $error-color;
	&:hover{
   background: rgba($error-color,0.8);
 }
}

.barra{
  width: 100%;
  background: none;
  border: none;
  padding: 10px;
  border-bottom: solid 1.5px $blue;
}

.link-barra{
  display: flex;
  flex-direction: row;
}

.copy-link{
  flex-shrink: 7;
  padding:0;
}

.url_btn{
	margin:0;
	padding:0;
  background: black;
  form{
    &:first-child{
      border-right: 1px solid $light;
      background: $blue;
    }
  }
  input{
    color: white;
  }
}

.icon-text{
  display: flex;
  cursor: pointer;
  flex-direction: column;
  &:hover{
   opacity: 0.9;
 }
}

.btn-none{
  top:20px;
  right:20px;
  position: absolute;
  background:none;
  border:none;
  z-index:1;
  color: white;
}