.no-navbar {
    margin-top: -$nav-size;
    margin-bottom: 25px;
}

.first {
    font-size: 40px;
    padding-bottom: -2vh;
}


/*---------#SCROLL-BUTTON-------*/

.span {
    display: inline;
    position: absolute;
    left: 50%;
    width: 24px;
    height: 24px;
    border-left: 3px solid white;
    border-bottom: 3px solid white;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb05 1.5s infinite;
    animation: sdb05 1.5s infinite;
    box-sizing: border-box;
    @media only screen and (min-width: 200px) and (max-width: 800px) {
        top: 80vh;
    }
    @media screen and (min-width: 800px) and (max-width: 1600px) {
        top: 60vh;
    }
}

.scroll {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
}

@-webkit-keyframes sdb05 {
    0% {
        -webkit-transform: rotate(-45deg) translate(0, 0);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: rotate(-45deg) translate(-20px, 20px);
        opacity: 0;
    }
}

@keyframes sdb05 {
    0% {
        transform: rotate(-45deg) translate(0, 0);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: rotate(-45deg) translate(-20px, 20px);
        opacity: 0;
    }
}


/*-----------------------#SECTIONS-------------*/

.h-section {
    //margin: 10px 20px 10px;
    @media only screen and (min-width: 200px) and (max-width: 800px) {
        padding: 5vh 0 8vh;
    }
    @media screen and (min-width: 800px) {
        padding: 10vh 0 10vh;
    }
}

.h-section--first {
    padding: 20vh 0 50vh;
    @media only screen and (min-width: 200px) and (max-width: 800px) {
        padding: 30vh 0 50vh;
    }
    @media screen and (min-width: 800px) and (max-width: 1600px) {
        padding: 35vh 0 45vh;
    }
    @media screen and (min-width: 1600px) and (max-width: 1950px) {
        padding: 40vh 0 40vh;
    }

}

.logo-big {
    width: 300px;
    height: auto;
    max-width: 100%;
}

 
 


/*-------------#BUTTONS-------------*/

.h-button {
    cursor: pointer;
    max-width: 230px;
    width: 100%;
    background-color: $blue;
    border: none;
    color: white;
    padding: 20px 0px 20px;
    border: 2px solid $blue;
    //box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);    
    display: inline-block;
    &:target {
        background-color: #28666c;
    }
    margin-top: 35px;
    @include breakpoint($min:md) {
       margin-top: 35px;
       margin-bottom:20px; 

    }
}

.h-button-white{
    @extend .h-button;
    font-family: TextFont;
    background-color: white;
    color: $black;
    margin-top: 15px;
    @include breakpoint($min: xs) {
            margin-top: 35px;
        }
}

.h-button-border{
    @extend .h-button;
    background: white;
    color: $black;
    border: 2px solid $blue;
}

/*-----------------------#SECTION_2--------------------*/


/*-------#HOW_TO-------*/

.how-img {
    width: 110px;
    height: 100px;
    margin: 20px 0 20px;
    
    @include breakpoint($min:sm) {
        margin-right: 0px;
    }
 @include breakpoint($min:xs) {
        margin-right: 0px;
    }
}

.how-container{
    margin-top: 40px;
    margin-bottom: 40px;
}

.cont-space{
    padding: 0 25px;
}

