/*------------------------------------*\
 #BOXES
 \*------------------------------------*/
.boxes-container {
  padding: 0 15px;
}

 .box{
    background: white;
    margin: 5px;
    text-align: left;
  }

 .box-container{
   display:flex;
   flex-direction:column;
 }

.basic-info{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.box-title-desc, .extra-info{
	display: flex;
	flex-direction: column;
  width: 100%;
  padding: 5px 0 5px 15px;
  color: black;
}

.extra-info{
  height: 110px;
  display: flex;
  justify-content: space-around;
}

.box-title-desc{
  padding-bottom: 15px;
  border-bottom: solid 1.5px $blue;
}

.space{
	margin-bottom: $spacing--xx-large;
}

.img-boxes{
  width: 15px;
  height: auto;
  margin-top: 8px;
  align-self: center !important;
}

.img-boxes-external{
  @extend .img-boxes;
  margin: 9px 6px 7px 7px;
}
/*------------------------------------*\
 #BOXES_NAV_BAR_STATUS
 \*------------------------------------*/

 .box__nav{
  width: 100%;
  align-self: center;
  margin-bottom: 0px;
  padding: 0px;

    li{
    	display: inline;
    }
  }

  .box__nav__item{
    color: $white;
   display: inline;
   border-width: 1px;
   margin: 0px;
   padding-top: 12px;
   padding-bottom:12px;
   font-weight: 600;
   display: inline-block;
   text-align: center;
   background: none;
   width: 80px;

   &.active{
    background: none;
    border-bottom: 5px solid $blue;
    color: $white;
    width: 100%;
    &:hover{
      opacity: 1;
    }
  }
}

.new-box-btn{
  background: $blue;
  color: white;
  &:hover{
   transition: all 0.2s ease-in-out;
   opacity: 0.7;
 }
}

.icons-{
  padding: 4px;
  text-align: center;
  background: black;
  border: none;
  cursor: pointer;
}

.icons-blue{
  @extend .icons-;
  background: $blue;
}

.container-fluid{
	.father{
		padding-bottom: 3vh;
	}
	.title{
		padding: initial;
	}
}

.head-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.nav-bar-boxes-{
  background: black;
  padding-top:80px;
}

.boxes-navbar-container{
  padding: 10px 25px 0px;
    @include breakpoint($min: md){
      padding: 10px 80px 0px;
    }
}

.new-box{
  box-shadow: 0px 0px 15px 4px rgba(76,167,175,0.35);
}

.text-mob-{
  display: block;
  @include breakpoint($min: xxs){
      display: none;
    }
}
//--------Date_picker-------

.xdsoft_calendar{
  background: $blue;
  font-family: TextFont;
}
.xdsoft_timepicker{
  font-family: TextFont;
}

.xdsoft_mounthpicker{
  font-family: TextFont;
}
.xdsoft_datetimepicker .xdsoft_label{
  padding:0;
}
//tabela com os dias
.xdsoft_datetimepicker .xdsoft_calendar td, .xdsoft_datetimepicker .xdsoft_calendar th{
  background: none;
  border: none;
}
.xdsoft_datetimepicker *{
  background:none;
}
//Dia de hoje
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today{
  border: 1px solid $blue;
}
//dia activo
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current{
  box-shadow: none;
  background: $blue;
}
//Hover do dia
.xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover{
  background: $blue !important;
  opacity: 0.5 !important;
}
//cor da linha dos dias da semana
.xdsoft_datetimepicker .xdsoft_calendar th{
  color: black;
}
//tabela com as horas
.xdsoft_time_box{
  border: none;
}
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div{
  border: none;
}
.xdsoft_scrollbar > .xdsoft_scroller{
  color: $blue;
}