// RESPONSIVE BREAKPOINTS
// A function by Tim Knight posted on medium: 
// https://medium.com/sass-syntactically-awesome-style-sheets/889927b37740
@mixin breakpoint($min: 0, $max: 0) {
    $type: type-of($min);
    @if $type==string {
        @if $min==xxs {
            @media (max-width: 569px) {
                @content;
            }
        }
        //mobile
        @else if $min==xs {
            @media (min-width: 570px) and (max-width: 640px) {
                @content;
            }
        }
        //tablet
        @else if $min==sm {
            @media screen and (min-width: 641px) and (max-width: 750px)  {
                @content;
            }
        }
        //medium
        @else if $min==md {
            @media (min-width: 750px) and (max-width: 1280px) {
                @content;
            }
        }
        //large
        @else if $min==lg {
            @media (min-width: 1280px) and (max-width: 1600px) {
                @content;
            }
        }
        //x-large
        @else if $min==xl {
            @media (min-width: 1600px) {
                @content;
            }
        }
        @else {
            @warn "Breakpoint mixin supports: xs, sm, md, lg";
        }
    }
    @else if $type==number {
        $query: "all" !default;
        @if $min !=0 and $max !=0 {
            $query: "(min-width: #{$min}) and (max-width: #{$max})";
        }
        @else if $min !=0 and $max==0 {
            $query: "(min-width: #{$min})";
        }
        @else if $min==0 and $max !=0 {
            $query: "(max-width: #{$max})";
        }
        @media #{$query} {
            @content;
        }
    }
}

@mixin backface-visibility($backface-visibility) {
    -webkit-backface-visibility: $backface-visibility;
    -moz-backface-visibility: $backface-visibility;
    backface-visibility: $backface-visibility;
}

@mixin simple-transition($time) {
    -webkit-transition: all $time;
    -moz-transition: all $time;
    transition: all $time;
}


@mixin scale($size) {
   -webkit-transform: scale($size);
    -moz-transform: scale($size);
    -ms-transform: scale($size);
    transform: scale($size);
}


@mixin translateXandY($translation) {
    -webkit-transform: translateX($translation)translateY($translation);
    -moz-transform: translateX($translation)translateY($translation);
    -ms-transform: translateX($translation)translateY($translation);
    transform: translateX($translation)translateY($translation);
}