/*------------------------------------*\
#NEW_BOX
\*-----------------------------------*/

.form__wrap {
    max-width: 100%;
    padding: $spacing--small $spacing--medium;
    background: rgba($light, 0.2);
}

.form__wrap_msg {
    width: 100%;
    text-align: left;
}

.form__wrap--2col {
    width: $form-width;
    @media (min-width: 640px) {
        width: $form-width*2;
    }
}

textarea {
    width: 100%;
    height: 5vh;
    border-color: $light;
    resize: none;
    &:focus {
        outline: none;
        border: 1px solid rgba($main-color-dark, 0.5);
        box-shadow: inset 0 1px 1px rgba($main-color-dark, 0.3);
    }
}

.multiline-input textarea {
    height: 8vh;
}

input {
    width: 100%;
    padding: 10px 0;
}

.form__block {
    margin: 20px 0px;
    @include breakpoint ($min:lg) {
        margin: 30px 0px;
    }
    @include breakpoint ($min:xl) {
        margin: 30px 0px;
    }
}

.label__info {
    span {
        font-weight: 600;
    }
    font-size: 12px;
    padding: $spacing--medium;
    background: rgba($main-color, 0.2);
    color:$main-color-dark;
    border-radius: 4px;
    @media (min-width: 640px) {
        margin-top: 33px;
    }
}

/*------------------------------------*\
#CLOSE_BOX
\*------------------------------------*/

.close-box {
    list-style: none;
    text-align: left;
    color: black;
    padding: 0;
}

.sub {
    text-align: left;
}

.top {
    margin-bottom: 7%;
}

.logosucess {
    height: 40vh;
}

.name-frame{
    background: $blue;
    color: white;
    border-radius: 10px;
    padding: 3px 6px 3px 6px;
    margin-right: 3px;
}

.hawkpost__block{
    max-height: 80px;
    width: 100%;
    cursor: pointer;
}

.hawkpost-text {
    margin: 0px;
    padding: 0px;
    display: none;
}

.open__hawkpost__text{
    overflow: hidden;
    max-height: 600px !important;
    .hawkpost-text {
        display: block;
    }
}
