/*---------------MODAL_XS---------------*/
 
 .md-modal-xs {

     width: 35vw;
     margin: 0 auto 0;
     @include breakpoint ($min: xxs) {

         width: 100vw;
         max-height: 110vh;
     }

     @include breakpoint ($min: xs) {

         width: 90vw;
     }
     @include breakpoint ($min: sm) {

         width: 71vw;
     }
     @include breakpoint ($min: md) {

         width: 63vw;
     }
     @include breakpoint ($min: lg) {

         width: 45vw;
     }
     @include breakpoint ($min: xl) {

         width: 50vw;
     }
     
     li {
         list-style: none;
     }
     .title {
         
         margin-bottom: 0;
     }
     .btn-default {
         padding: 0 0 0;
         height: 6vh;
         
         
     }
     @extend .md-modal;
     
 }
 
 .md-content-xs {
     @extend .md-content;
     //padding: 30px 30px 0px;
     background-color: #1d1d1f;
 
    
    
       
    
}