/* General styles for the modal */


/*
Styles for the html/body for special modal where we want 3d effects
Note that we need a container wrapping all content on the page for the
perspective effects (not including the modals and the overlay).
*/

.md-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 70vw;

    margin: 0 auto;

@include breakpoint ($min: xs){
    width: 80vw;

}
@include breakpoint ($min: xxs){
    width: 70vw;

}

    z-index: 2000;
    visibility: hidden;
}

.md-show {
    visibility: visible;
}

.md-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    background: rgba(0,0,0,0.3);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.md-show ~ .md-overlay {
    opacity: 1;
    visibility: visible;
}


/* Content styles */

@keyframes example {
    from {
        width: 10%;
    }
    to {
        //avanço da linha azul
        width: 90%;
    }
}

.md-content {
    color: $light;
    background: $grey;
    position: relative;
    border-radius: 3px;
    margin: 0 auto;
    max-width: 800px;
    width: 100%;


}

.md-content h3 {
    margin: 0;
    padding: 0.4em;
    text-align: center;
    opacity: 0.8;
    border-radius: 3px 3px 0 0;
}

.md-content {
    p {
        text-align: left;
    }
}

.md-content > div {
    padding: 15px 30px 30px;
    margin: 0;
}

.md-content > .row-s{
  margin: auto;
}

.md-content > div p {
    margin: 0;
    padding: 10px 0;
    @include breakpoint ($min: xxs){
    padding: 6px 0;

}
}
.fa-github{
    font-size: 20px;
    margin: 5px;
}


.btn-modal {
    @extend .btn-default;
    max-width: 200px;
    width: 100%;
    margin-top: 7vh;

    @include breakpoint ($min: sm){
		margin-top: 2vh;
}
}


/* Individual modal styles with animations/transitions */


/* Effect 1: Fade in and scale up */

.md-effect-1 .md-content {
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.md-show.md-effect-1 .md-content {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

.md-table {
    word-break: break-all;
}

.md-scrollable-div {
    max-height: 400px;
    overflow-y: scroll;
}
